import { Component, ReactNode } from 'react';
import cx from 'classnames';
import Link from '../atoms/link/Link';
import { TravelPartNavigationViewModel } from '../../models/TravelPartNavigationViewModel';
import { ParameterKeys } from '../../constants/parameterKeys';
import { asEditable } from '../../helpers/sitecore';
import RoadTrippersLink from '../atoms/roadTrippersLink/RoadTrippersLink';

interface TravelPartNavigationState {
  activeId?: string | undefined;
}

export const getHashUrlOnly = (): string | undefined => {
  return window.location.hash.split(ParameterKeys.dayKey)[1];
};

class TravelPartNavigation extends Component<TravelPartNavigationViewModel, TravelPartNavigationState> {
  constructor(props: TravelPartNavigationViewModel) {
    super(props);

    this.hashChangeHandler = this.hashChangeHandler.bind(this);

    this.state = {};
  }

  hashChangeHandler(): void {
    this.setState({
      activeId: getHashUrlOnly(),
    });
  }

  componentDidMount(): void {
    const { travelPartLinks } = this.props;

    if (travelPartLinks.length === 0) {
      return;
    }

    const isOnTargetPage = travelPartLinks[0].url?.[0] === '#';
    const activeId = window.location.hash
      ? getHashUrlOnly()
      : isOnTargetPage
      ? this.props.travelPartLinks[0].targetId
      : undefined;

    this.setState({ activeId });

    window.addEventListener('hashchange', this.hashChangeHandler);
  }

  componentWillUnmount(): void {
    window.removeEventListener('hashchange', this.hashChangeHandler);
  }

  render(): ReactNode {
    const {
      componentTitle,
      contentTitle,
      travelPartLinks,
      editBlogLink,
      extraMarginAround,
      extraText,
      roadTrippersCode,
    } = this.props;

    const { activeId } = this.state;

    return (
      <section>
        <div
          id="reis-van-dag-tot-dag"
          className={cx('tab-pane fade active in', extraMarginAround && 'travelpartnavigation--extramargin')}
        >
          {travelPartLinks && travelPartLinks.length > 0 && (
            <>
              <p className="heading">{componentTitle}</p>
              {contentTitle && <h1 className="travelpartnavigation__contenttitle">{contentTitle}</h1>}
              {extraText && (
                <p className="travelpartnavigation__extratext" dangerouslySetInnerHTML={asEditable(extraText)} />
              )}
              {travelPartLinks.map((link, index) => (
                <div
                  key={index}
                  className={cx('illustrated image-right', activeId === link.targetId && 'illustrated--active')}
                >
                  <Link {...link} />
                </div>
              ))}
            </>
          )}

          {roadTrippersCode && <RoadTrippersLink roadTrippersCode={roadTrippersCode} text="Open op Roadtrippers" />}
          {editBlogLink && <Link rel="nofollow" class="edit-blog" {...editBlogLink} />}
        </div>
      </section>
    );
  }
}

export default TravelPartNavigation;
