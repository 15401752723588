import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import SimpleConsentForm from './SimpleConsentForm';
import { getCurrentLevel, handleConsentPick } from './utils';
import FullConsentForm from './FullConsentForm';
import { ConsentDialogViewModel } from '../../models/ConsentDialogViewModel';

const ConsentDialog: FunctionComponent<ConsentDialogViewModel> = props => {
  const isOnCookieInformationPage =
    typeof window !== 'undefined' && props.noDialogPaths.includes(window.location.pathname);
  const currentLevel = getCurrentLevel();
  const [container, setContainer] = useState<HTMLDivElement | undefined>(undefined);
  const [dialog, setDialog] = useState<'simple' | 'full' | null>(null);

  if (currentLevel === undefined && dialog === null && !isOnCookieInformationPage) {
    setDialog('simple');
  }

  // Create the portal div once and remove it when the modal gets destroyed.
  useEffect(() => {
    if (dialog === null) {
      return;
    }

    // Create an ID for the current modal.
    const body = document.body;
    body.classList.add('haspopup');

    const el = document.createElement('div');
    body.appendChild(el);
    setContainer(el);

    return () => {
      document.body.removeChild(el);
      body.classList.remove('haspopup');
    };
  }, [dialog]);

  // Show full dialog when click on a link / button with class or id cookiesettings.
  useEffect(() => {
    const elements = document.querySelectorAll('.cookiesettings');

    if (elements.length === 0) {
      return;
    }

    const handleClick = (e: Event) => {
      e.preventDefault();
      setDialog('full');
    };

    elements.forEach(el => el.addEventListener('click', handleClick));

    return () => elements.forEach(el => el.removeEventListener('click', handleClick));
  }, []);

  const handleConsentSelect = useCallback((level: number) => {
    handleConsentPick(level);
    setDialog(null);
  }, []);

  if (!container || !dialog) {
    return null;
  }

  if (dialog === 'simple') {
    return createPortal(
      <SimpleConsentForm
        viewModel={props}
        onDeny={() => handleConsentSelect(0)}
        onAccept={() => handleConsentSelect(2)}
        onFull={() => setDialog('full')}
      />,
      container
    );
  }

  return createPortal(
    <FullConsentForm viewModel={props} initialLevel={currentLevel} onPickConsent={handleConsentSelect} />,
    container
  );
};

export default ConsentDialog;
